import React, { useContext } from "react";
import useFetchAirports from "../data/useFetchAirports.tsx";
import { Link } from "react-router-dom";
import sortByAirportName from "../../shared/utilities/sortByAirportName.tsx";
import { ConfigurationContext } from "../../App.tsx";

export default function StartPage() {
    const context = useContext(ConfigurationContext);
    const { data: airports, isLoading, error } = useFetchAirports(context.host);

    return (
        <div className="xl:w-1/3 mx-auto p-1">
            {isLoading && <div className="flex items-center justify-center h-screen">Laddar...</div>}
            {error && <div className="flex items-center justify-center h-screen">Ett fel uppstod</div>}
            {!isLoading && !error && airports.length === 0 && <div className="flex items-center justify-center h-screen">Inga flygplatser hittades</div>}
            {!isLoading && !error && airports.length > 0 &&
                <div className="my-2">
                    <h1>Välkommen hit</h1>
                    <p>Här kan du snabbt och enkelt hitta avgångar och ankomster på alla Swedavias flygplatser.</p>
                </div>
            }
            {!isLoading && !error && airports.sort(sortByAirportName).map((airport) => (
                <Link key={airport.iata} to={`/airports/${airport.iata}`}>
                    <div key={airport.iata} className="border rounded-lg overflow-hidden relative my-2">
                        <div className="border-b">
                            <img src={`${process.env.PUBLIC_URL}/images/${airport.iata}.jpg`} alt={airport.name} className="w-full h-48 object-cover object-top" />
                        </div>
                        <div className="p-2">
                            <h1>{airport.localDisplayName}</h1>
                        </div>
                    </div>
                </Link>
            ))}
        </div>
    );
}