import React from "react";
import { DateFormatter } from "../../shared/utilities/DateFormatter.js";
import IArrival from "../interfaces/IArrival.ts";
import getAirlineLogo from "../../shared/utilities/AirlineLogos.js";
import getAirlineName from "../../shared/utilities/AirlineNames.js";

interface IArrivalCardProps {
    data: IArrival;
}

export default function ArrivalCard(props: IArrivalCardProps) {
    const color = (function () {
        switch (props.data.locationAndStatus.flightLegStatus) {
            case "SCH":
                return 'gray';
            case "LAN":
                return 'green';
            case "CAN":
            case "DEL":
                return 'red';
            default:
                return <></>;
        }
    })();

    return (
        <div className={`p-1 border-b-slate-400 border-b-1`}>
            <div className="flex">
                <div className="flex items-center w-1/6">
                    <div className={`rounded-full bg-${color}-500 w-3 h-3 flex items-center justify-center mx-1`} />
                    {DateFormatter.toLocalTimeString(props.data.arrivalTime.scheduledUtc) ?? "N/A"}
                </div>
                <div className="flex items-center w-2/6">
                    {props.data.departureAirportSwedish}
                </div>
                <div className="flex items-center w-1/6" title={getAirlineName(props.data.airlineOperator.iata)}>
                    {getAirlineLogo(props.data.airlineOperator.iata)} {props.data.flightId}
                </div>
                <div className="flex items-center justify-end w-2/6">
                    {props.data.locationAndStatus.flightLegStatusSwedish.toUpperCase()}
                </div>
            </div>
        </div>
    );
}