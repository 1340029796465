import React, { } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import StartPage from "./startpage/presentation/StartPage.tsx";
import AirportView from "./airport/presentation/AirportView.tsx";

export const ConfigurationContext = React.createContext({ host: '' });

function App() {
  var apiBaseUrl = 'https://kollaflygetnu-api.azurewebsites.net';

  if (window.location.hostname === 'localhost')
    apiBaseUrl = 'http://localhost:3000';

  return (
    <Router>
      <ConfigurationContext.Provider value={{ host: apiBaseUrl }}>
        <div className='flex flex-col h-dvh lg:text-2xl'>
          <div className="grow overflow-y-auto">
            <Routes>
              <Route path="/" element={<StartPage />} />
              <Route path="airports/:iata" element={<AirportView />} />
            </Routes>
          </div>
        </div>
      </ConfigurationContext.Provider>
    </Router >
  );
}

export default App;