import React, { useContext, useState } from "react";
import { Drawer, Button } from "@mui/joy";
import { MdMenu, MdClose } from "react-icons/md";
import IArrival from "../../arrivals/interfaces/IArrival.ts";
import ArrivalCard from "../../arrivals/presentation/ArrivalCard.tsx";
import IDeparture from "../../departures/interfaces/IDeparture.ts";
import DepartureCard from "../../departures/presentation/DepartureCard.tsx";
import FlightList from "../../shared/components/FlightList.tsx";
import byArrivalTime from "../../arrivals/utilities/sortByArrivalTime.tsx";
import byTerminal from "../../shared/utilities/filterByTerminal.tsx";
import useFetchArrivals from "../../arrivals/data/useFetchArrivals.tsx";
import useFetchDepartures from "../../departures/data/useFetchDepartures.tsx";
import { Link, useParams } from "react-router-dom";
import byDepartureTime from "../../departures/utilities/sortByDepartureTime.tsx";
import { RiHome4Line } from "react-icons/ri";
import { ConfigurationContext } from "../../App.tsx";

export default function AirportView() {
    const { host } = useContext(ConfigurationContext);
    const { iata } = useParams<{ iata: string }>();

    const todayDate = new Date().toISOString().split('T')[0];
    const tomorrowDate = new Date(Date.now() + 86400000).toISOString().split('T')[0];
    const [selectedDate, setSelectedDate] = useState(todayDate);
    const [selectedTerminal, setSelectedTerminal] = useState<string>();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const params = useParams<{ iata: string }>();
    const arrivalData = useFetchArrivals(host, params.iata ?? '', selectedDate);
    const departureData = useFetchDepartures(host, params.iata ?? '', selectedDate);
    const [type, setType] = useState<'arrivals' | 'departures'>('arrivals');
    const arrivals = arrivalData.data as IArrival[];
    const departures = departureData.data as IDeparture[];
    const showArrivals = type === 'arrivals';
    const showDepartures = type === 'departures';
    const fetchingArrivals = showArrivals && arrivalData.isLoading;
    const fetchingDepartures = showDepartures && departureData.isLoading;
    const fetchArrivalsFailed = showArrivals && arrivalData.error;
    const fetchDeparturesFailed = showDepartures && departureData.error;
    const hasArrivals = showArrivals && arrivals.length > 0;
    const hasDepartures = showDepartures && departures.length > 0;
    const noArrivalsFound = showArrivals && !fetchingArrivals && !fetchArrivalsFailed && arrivals.length === 0;
    const noDeparturesFound = showDepartures && !fetchingDepartures && !fetchDeparturesFailed && departures.length === 0;

    const terminalsArrivals = arrivalData.data?.map((arrival) => arrival.locationAndStatus.terminal).filter((value, index, self) => self.indexOf(value) === index).sort();
    const terminalsDepartures = departureData.data?.map((departure) => departure.locationAndStatus.terminal).filter((value, index, self) => self.indexOf(value) === index).sort();

    if (!iata) {
        return <div className="flex flex-grow justify-center items-center">
            <div className="flex">
                <h1>Något gick fel (Iata-kod saknas)</h1>
            </div>
        </div>;
    }

    // Event handlers
    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event && event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    }

    const fillStyle = { color: "white", backgroundColor: "black", ":hover": { backgroundColor: "slategrey" } };
    const outlineStyle = { color: "black", backgroundColor: "white", border: 1, borderColor: "black", ":focus": { color: "white", backgroundColor: "black" }, ":hover": { backgroundColor: "slategrey" } };

    return (
        <div className="xl:w-1/3 mx-auto flex flex-col h-full items-center">

            <div className='flex flex-col overflow-y-auto w-full h-full'>
                {(fetchArrivalsFailed || fetchDeparturesFailed) && <div className="flex flex-grow justify-center items-center text-gray-500"><h1>Något gick fel</h1></div>}
                {(fetchingArrivals || fetchingDepartures) && <div className="flex flex-grow justify-center items-center"><h1>Laddar...</h1></div>}
                {
                    hasArrivals && !fetchArrivalsFailed &&
                    <>
                        <div className="flex items-center justify-center my-5">
                            <h1>Ankommande {selectedDate === todayDate ? 'idag' : 'imorgon'} {selectedTerminal} {iata}</h1>
                        </div>
                        <FlightList source={arrivals.sort(byArrivalTime).filter(byTerminal(selectedTerminal))} isLoading={arrivalData.isLoading}>
                            {
                                (flight: IArrival) => {
                                    return (<ArrivalCard data={flight} />);
                                }
                            }
                        </FlightList>
                    </>
                }
                {
                    hasDepartures && !fetchDeparturesFailed &&
                    <>
                        <div className="flex items-center justify-center my-5">
                            <h1>Avgångar {selectedDate === todayDate ? 'idag' : 'imorgon'} {selectedTerminal} {iata}</h1>
                        </div>
                        <FlightList source={departures.sort(byDepartureTime).filter(byTerminal(selectedTerminal))} isLoading={departureData.isLoading}>
                            {
                                (flight: IDeparture) => {
                                    return (<DepartureCard data={flight} />);
                                }
                            }
                        </FlightList>
                    </>
                }
                {
                    (noArrivalsFound || noDeparturesFound) &&
                    <div className="flex flex-grow items-center justify-center">
                        <p className="text-gray-500">Inga flygningar hittades</p>
                    </div>
                }
                {
                    (arrivals || departures) &&
                    <div className="flex bg-black py-2 px-1">
                        <div className="w-1/3"></div>
                        <div className="flex justify-center w-1/3">
                            <Link to="/">
                                <div className="p-1 text-white">
                                    <RiHome4Line size={"2rem"} />
                                </div>
                            </Link>
                        </div>
                        <div className="flex w-1/3 justify-end items-center" onClick={toggleDrawer(true)}>
                            <div className="text-white p-1">
                                <MdMenu size={"2rem"} />
                            </div>
                        </div>
                    </div>
                }
            </div >

            <Drawer open={drawerOpen} anchor="bottom" onClose={toggleDrawer(false)}>
                <div className="lg:w-1/3 md:w-1/2 w-full mx-auto h-full flex flex-col">
                    <div className="flex-grow">
                        <div className="w-full">
                            <div className="flex gap-1 py-2 px-1">
                                <Button size="lg" sx={showArrivals ? fillStyle : outlineStyle} onClick={() => { setType("arrivals"); }} fullWidth={true}>Ankommande</Button>
                                <Button size="lg" sx={showDepartures ? fillStyle : outlineStyle} onClick={() => { setType("departures"); }} fullWidth={true}>Avgående</Button>
                            </div>
                            <div className="flex gap-1 py-2 px-1">
                                <Button size="lg" sx={selectedDate === todayDate ? fillStyle : outlineStyle} onClick={() => setSelectedDate(todayDate)} fullWidth={true}>Idag</Button>
                                <Button size="lg" sx={selectedDate === tomorrowDate ? fillStyle : outlineStyle} onClick={() => setSelectedDate(tomorrowDate)} fullWidth={true}>Imorgon</Button>
                            </div>
                            {
                                ((showArrivals && terminalsArrivals.length > 1) || (showDepartures && terminalsDepartures.length > 1)) && <div className="px-1">
                                    <h2>Terminal:</h2>
                                </div>
                            }
                            <div className="flex gap-1 py-2 px-1">
                                {
                                    showArrivals && terminalsArrivals.length > 1 && terminalsArrivals.map((terminal) => <Button key={terminal} size="lg" variant={selectedTerminal === terminal ? "solid" : "outlined"} onClick={() => setSelectedTerminal(terminal)}>{terminal}</Button>)
                                }
                                {
                                    showDepartures && terminalsDepartures.length > 1 && terminalsDepartures.map((terminal) => <Button key={terminal} size="lg" variant={selectedTerminal === terminal ? "solid" : "outlined"} onClick={() => setSelectedTerminal(terminal)}>{terminal}</Button>)
                                }
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <div className="rounded-full bg-black text-white p-1 m-2" onClick={toggleDrawer(false)}>
                            <MdClose size={"2rem"} />
                        </div>
                    </div>
                </div>
            </Drawer>
        </div>
    );
}