import { useState, useEffect } from "react";
import { IAirport } from "../../airport/interfaces/IAirport.ts";

export default function useFetchAirports(host: string): {
    data: IAirport[];
    isLoading: boolean;
    error: string | null;
} {
    const [data, setData] = useState<IAirport[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        setData([]);
        setIsLoading(true);
        setError(null);

        const endpoint = `${host}/api/airports`;
        fetch(endpoint)
            .then((response) => {
                if (!response.ok) {
                    console.log(
                        `${new Date().toLocaleTimeString()}: Error fetching data`
                    );
                    console.log(
                        `${new Date().toLocaleTimeString()}: ${endpoint}: ${response.statusText
                        }`
                    );
                    setError(response.statusText);
                }

                return response.json();
            })
            .then((data: any) => {
                setData(data);
            })
            .catch((reason) => {
                setError(reason);
                console.log(`${new Date().toLocaleTimeString()}: Error fetching data`);
                console.log(
                    `${new Date().toLocaleTimeString()}: ${endpoint}: ${reason}`
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [host]);

    return { data, isLoading, error };
}